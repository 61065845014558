.mainContainer{
    background: #F5F9F9;
    width: calc(100% - 200px);
    padding: 0 100px 24px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: rgb(28, 32, 46);
}


@media (max-width: 600px) {
    .mainContainer{
        width: calc(100% - 100px);
        padding: 0 50px 24px;
    }
  }

@media (max-width: 400px) {
    .mainContainer{
        width: calc(100% - 48px);
        padding: 0 24px 24px;
    }
  }