.mainContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F5F9F9;
}

.mainHeading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}

.mainHeading > img{
 max-height: 100px;
 max-width: 100px;
}

.chatSection {
  position: relative;
  max-height: calc(100vh - 255px);
  min-height: calc(100vh - 260px);
  overflow-y: scroll;
  width: 80%;
  margin: auto;
  padding: 24px 50px;
  display: flex;
  flex-direction: column;
}

/* .chatSection::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../../public/images/WellmaBotLogo.png");
  background-size: 75% auto;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.1;
  z-index: -1;
} */

.chatSection::-webkit-scrollbar {
  width: 0.4em;
}

.chatSection::-webkit-scrollbar-thumb {
  background-color: #7D68A7;
  border-radius: 20px;
}

.markdownTextArea > p{
  margin: 0;
}

.messageByUser {
  max-width: 50%;
  width: fit-content;
  background: #9f8ac2;
  color: #fff;
  padding: 24px;
  margin-bottom: 24px;
  border-radius: 10px;
  border-top-right-radius: 0;
  align-self: flex-end;
  line-height: 24px;
}

.messageByAI {
  position: relative;
  max-width: 70%;
  width: fit-content;
  color: #262f42;
  align-self: flex-start;
  margin-bottom: 24px;
}

.messageByAI::before {
  content: "";
  position: absolute;
  top: -15px;
  left: -15px;
  width: 40px;
  height: 40px;
  background-image: url("../../../public/images/WellmaBotLogo.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.75);
  z-index: 2;
}

.messageByAIInnerDiv{
  background: #cec4e9;
  max-width: 100%;
  width: fit-content;
  height: auto;
  line-height: 24px;
  padding: 24px;
  margin-bottom: 8px;
  border-radius: 10px;
  border-top-left-radius: 0;
}

.inputSection {
  width: 760px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 24px;
}

.promptInput {
  min-height: 24px;
  width: 80%;
  border: 1px #c1c1c1 solid;
  border-radius: 8px;
  padding: 14px 16px;
}

.disabledPromptInput {
  min-height: 24px;
  width: 80%;
  border: 1px #c1c1c1 solid;
  border-radius: 8px;
  padding: 14px 16px;
  cursor: not-allowed;
}

.submitButton {
  height: 50px;
  color: #fff;
  background: #9883BB;
  padding: 10px 15px;
  border: 0;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
}

.submitButton:hover{
  background: #7D68A7;
}

.disabledSubmitButton {
  height: 50px;
  color: #fff;
  background: #C7BDE2;
  padding: 10px 15px;
  border: 0;
  border-radius: 10px;
  font-size: 16px;
  cursor: not-allowed;
}

.disclaimerLine {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  text-align: center;
  color: #262f42;
}

.actionButtons{
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

@media (max-width: 1200px) {
  .chatSection {
    width: 90%;
  }
}

@media (max-width: 765px) {
  .inputSection {
    width: 550px;
  }
  .chatSection {
    width: calc(100% - 100px);
  }
  .messageByAI {
    max-width: 80%;
  }
  .messageByUser {
    max-width: 70%;
  }
}

@media (max-width: 555px) {
  .inputSection {
    width: 400px;
  }
  .promptInput {
    width: 70%;
  }
  .messageByAI {
    max-width: 95%;
  }
  .chatSection {
    width: calc(100% - 32px);
    padding: 24px 16px;
  }
}

@media (max-width: 400px) {
  .inputSection {
    width: 100%;
  }
  .promptInput {
    width: 70%;
  }
}


/* HTML: <div class="loader"></div> */
.animatedLoader {
  width: 40px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side,#413c3c 90%,#00000000);
  background: 
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 50%;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
    20%{background-position:0%   0%, 50%  50%,100%  50%}
    40%{background-position:0% 100%, 50%   0%,100%  50%}
    60%{background-position:0%  50%, 50% 100%,100%   0%}
    80%{background-position:0%  50%, 50%  50%,100% 100%}
}