@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: "Inter";
}

body {
  margin: 0;
  font-family: "Inter";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Inter";
}

/* antd */
.ant-collapse-header-text {
  margin-inline-end: auto;
  font-weight: bold !important;
}

:where(.css-dev-only-do-not-override-1r7tc63).ant-tree-show-line
  .ant-tree-switcher {
  background: #F5F9F9 !important;
}

.ant-tree-show-line
  .ant-tree-switcher {
  background: #F5F9F9 !important;
}

.ant-collapse
  .ant-collapse-content
  > .ant-collapse-content-box {
  padding: 16px 16px;
  background: #F5F9F9 !important;
}

.ant-modal .ant-modal-content {
  position: relative;
  background-color: #F5F9F9 !important;
  background-clip: padding-box;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
  padding: 20px 24px;
}

.ant-modal .ant-modal-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  word-wrap: break-word;
  background-color: #F5F9F9 !important;
}

.ant-tree {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  background: #F5F9F9 !important;
  border-radius: 6px;
  transition: background-color 0.3s;
}

.ant-btn-primary {
  color: #fff;
  background-color: #518074;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}
.ant-tree-show-line .ant-tree-switcher {
  background: #F5F9F9 !important;
}

.ant-tree {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
  background: #F5F9F9 !important;
  border-radius: 6px;
  transition: background-color 0.3s;
}

.ant-modal .ant-modal-content {
  position: relative;
  background-color: #F5F9F9 !important;
  background-clip: padding-box;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
  padding: 20px 24px;
}
.ant-modal .ant-modal-header {
  color: rgba(0, 0, 0, 0.88);
  background: #F5F9F9 !important;
  border-radius: 8px 8px 0 0;
  margin-bottom: 8px;
}
.ant-collapse .ant-collapse-content {
  color: rgba(0, 0, 0, 0.88);
  background-color: #F5F9F9 !important;
  border-top: 1px solid #d9d9d9;
}

.ant-collapse .ant-collapse-content {
  color: rgba(0, 0, 0, 0.88);
  background-color: #F5F9F9 !important;
  border-top: 1px solid #d9d9d9;
}