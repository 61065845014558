.mainContainer {
  width: 100%;
  max-height: 100vh;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f5f9f9;
}

.headingSection {
  width: 100%;
  min-height: 70px;
}
.headingSectionTopPart {
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.headingSectionTopPart > img {
  max-height: 50px;
  max-width: 50px;
}
.headingSectionDisclaimer {
  width: 100%;
  text-align: center;
  font-size: 10px;
}
.chatSection {
  position: relative;
  /* max-height: 100%;
  min-height: 100%; */
  overflow-y: scroll;
  width: 95%;
  margin: auto;
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.chatSection::-webkit-scrollbar {
  width: 0.4em;
}

.chatSection::-webkit-scrollbar-thumb {
  background-color: #00000000;
  border-radius: 20px;
}

.markdownTextArea > p {
  margin: 0;
}

.messageByUser {
  max-width: 100%;
  width: fit-content;
  background: #9f8ac2;
  color: #fff;
  padding: 12px;
  margin-bottom: 8px;
  border-radius: 10px;
  border-top-right-radius: 0;
  align-self: flex-end;
  line-height: 16px;
  font-size: 12px;
}

.messageByAI {
  position: relative;
  max-width: 100%;
  width: fit-content;
  color: #262f42;
  align-self: flex-start;
  margin-bottom: 8px;
}

.messageByAIInnerDiv {
  background: #cec4e9;
  max-width: 100%;
  width: fit-content;
  height: auto;
  line-height: 16px;
  padding: 12px;
  margin-bottom: 8px;
  border-radius: 10px;
  border-top-left-radius: 0;
  font-size: 12px;
}

.inputSection {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 10px 3px 5px;
}

.promptInput {
  min-height: 24px;
  width: 75%;
  border: 1px #c1c1c1 solid;
  border-radius: 8px;
  padding: 14px 16px;
}

.promptInput:focus {
  border: none;
}

.disabledPromptInput {
  min-height: 24px;
  width: 80%;
  border: 1px #c1c1c1 solid;
  border-radius: 8px;
  padding: 14px 16px;
  cursor: not-allowed;
}

.submitButton {
  height: 50px;
  color: #fff;
  background: #9883bb;
  padding: 10px 15px;
  border: 0;
  border-radius: 10px;
  font-size: 14px;
  cursor: pointer;
}

.submitButton:hover {
  background: #7d68a7;
}

.disabledSubmitButton {
  height: 50px;
  color: #fff;
  background: #c7bde2;
  padding: 10px 15px;
  border: 0;
  border-radius: 10px;
  font-size: 16px;
  cursor: not-allowed;
}

.actionButtons {
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

/* HTML: <div class="loader"></div> */
.animatedLoader {
  width: 40px;
  aspect-ratio: 3;
  --_g: no-repeat radial-gradient(circle closest-side, #413c3c 90%, #00000000);
  background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
  background-size: calc(100% / 3) 50%;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%;
  }
  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%;
  }
  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%;
  }
  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%;
  }
}
